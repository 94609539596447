// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login'
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500'
};

export const PATH_USER = {
  root: '/user',
  list: '/user/list',
  new: '/user/new'
};

export const PATH_PRODUCT = {
  root: '/product',
  stockList: '/product/stock/list',
  mpStockList: '/product/mp-stock/list',
  allStockList: '/product/all-stock/list',
  priceList: '/product/price/list',
  nomenclatureList: '/product/nomenclature/list',
  detail: '/product/'
};
