import { useTranslation } from 'react-i18next';
// material
import { ruRU } from '@mui/material/locale';

// ----------------------------------------------------------------------

const LANGS = [
  {
    label: 'Russian',
    value: 'ru',
    systemValue: ruRU,
    icon: '/static/icons/ic_flag_en.svg'
  }
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const currentLang = LANGS[0];

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS
  };
}
