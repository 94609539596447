// routes
// components
import SvgIconStyle from '../../components/SvgIconStyle';
import { PATH_USER, PATH_PRODUCT } from '../../routes/paths';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    roles: ['admin', 'operator'],
    items: [
      {
        title: 'Пользователи',
        path: PATH_USER.list,
        icon: ICONS.user,
        roles: ['admin']
      },
      {
        title: 'Остатки на складах Гипера',
        path: PATH_PRODUCT.stockList,
        icon: ICONS.kanban,
        roles: ['admin', 'operator']
      },
      {
        title: 'Остатки МП',
        path: PATH_PRODUCT.mpStockList,
        icon: ICONS.kanban,
        roles: ['admin', 'operator']
      },
      {
        title: 'Консолидированный сток',
        path: PATH_PRODUCT.allStockList,
        icon: ICONS.kanban,
        roles: ['admin', 'operator']
      },
      {
        title: 'Номенклатура',
        path: PATH_PRODUCT.nomenclatureList,
        icon: ICONS.kanban,
        roles: ['admin', 'operator']
      }
    ]
  }
];

export default sidebarConfig;
