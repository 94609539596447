import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
// ----------------------------------------------------------------------

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'user',
          children: [
            { element: <Navigate to="/user/list" replace /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':id/edit', element: <UserCreate /> }
          ]
        },
        {
          path: 'product',
          children: [
            { element: <Navigate to="/product/stock/list" replace /> },
            { path: 'stock/list', element: <ProductStockList /> },
            { path: 'mp-stock/list', element: <ProductMpStockList /> },
            { path: 'all-stock/list', element: <ProductAllStockList /> },
            { path: 'price/list', element: <PriceList /> },
            { path: 'nomenclature/list', element: <ProductNomenclatureList /> }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      )
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
// Dashboard
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const ProductStockList = Loadable(lazy(() => import('../pages/dashboard/ProductStockList')));
const ProductMpStockList = Loadable(lazy(() => import('../pages/dashboard/ProductMpStockList')));
const ProductAllStockList = Loadable(lazy(() => import('../pages/dashboard/ProductAllStockList')));
const PriceList = Loadable(lazy(() => import('../pages/dashboard/PriceList')));
const ProductNomenclatureList = Loadable(
  lazy(() => import('../pages/dashboard/ProductNomenclatureList'))
);
// Main
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
