import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { ProductStock } from '../../@types/product';

// ----------------------------------------------------------------------

type ProductState = {
  isLoading: boolean;
  error: boolean;
  product: null | ProductStock;
  productStockList: ProductStock[];
  productStockTotalRows: number;
};

const initialState: ProductState = {
  isLoading: false,
  error: false,
  product: null,
  productStockList: [],
  productStockTotalRows: 0
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    // GET MANAGE USERS
    getProductStockListSuccess(state, action) {
      state.isLoading = false;
      state.productStockList = action.payload.rows;
      state.productStockTotalRows = action.payload.total_rows;
    }
  }
});

// Reducer
export default slice.reducer;

export function getProductStockList(
  searchCode: string,
  rowsPerPage: string | number,
  page: number,
  orderBy: string,
  order: string
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      page += 1;
      const response = await axios.get(
        '/api/product/stock' +
          '?keyword=' +
          searchCode +
          '&rowPerPage=' +
          rowsPerPage +
          '&page=' +
          page +
          '&orderBy=' +
          orderBy +
          '&order=' +
          order
      );
      const { data } = response.data;
      dispatch(slice.actions.getProductStockListSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getProductMpStockList(
  searchCode: string,
  rowsPerPage: string | number,
  page: number,
  orderBy: string,
  order: string
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      page += 1;
      const response = await axios.get(
        '/api/product/mp-stock' +
          '?keyword=' +
          searchCode +
          '&rowPerPage=' +
          rowsPerPage +
          '&page=' +
          page +
          '&orderBy=' +
          orderBy +
          '&order=' +
          order
      );
      const { data } = response.data;
      dispatch(slice.actions.getProductStockListSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProduct(orderId: number | string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/product/' + orderId);
      const { data } = response.data;
      dispatch(slice.actions.getProductSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
